<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <v-autocomplete
          v-model="selectIncode"
          :items="incodes"
          label="社員氏名"
          :rules="[Rules.Required]"
          outlined
          :disabled="!add"
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-card>
          <v-tabs v-model="tab" background-color="light-blue darken-1" dark>
            <v-tabs-slider color="blue-grey lighten-5"></v-tabs-slider>
            <v-tab v-for="item in tabItems" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-card>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <!-- <v-autocomplete
              v-model="selectEatMeal"
              :items="eatMealItems"
              label="食事有無初期値"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-form-select"
              return-object
              class="mt-5"
            >
            </v-autocomplete> -->
            <!--<v-text-field
              v-model.number="standardWorkTime"
              label="標準勤務時間(有給取得時間)"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.05"
              suffix="時間"
              outlined
              class="mt-5"
            ></v-text-field> -->
            <!--v-text-field
              v-model.number="defaultDistance"
              label="標準移動距離"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.01"
              suffix="km"
              outlined
            ></v-text-field-->
            <v-autocomplete
              v-model="selectWeekWorkStart"
              :items="weekWorkStartItems"
              label="週起算日"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-form-select"
              return-object
            ></v-autocomplete>
            <v-autocomplete
              v-model="selectpaymentDayKbn"
              :items="paymentDayKbnItems"
              label="有給付与区分"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-form-select"
              return-object
            >
            </v-autocomplete>
            <!-- <v-autocomplete
              v-model="selectCarType"
              :items="carTypeItems"
              label="車種"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-form-select"
              return-object
            >
            </v-autocomplete> -->
            <v-autocomplete
              v-model="selectflex"
              :items="flexItems"
              label="変形労働制フラグ"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-form-select"
              return-object
            >
            </v-autocomplete>
          </v-tab-item>
          <v-tab-item>
            <Btn color="info" icon="plus" text @click="addShozoku()"
              >所属追加</Btn
            >
            <table class="my-5">
              <thead>
                <tr>
                  <th v-for="(header, i) in subHeaders" :key="i">
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in subDetails" :key="index">
                  <td>{{ item.displayName }}</td>
                  <td>
                    <v-text-field
                      v-model.number="item.amount1"
                      type="number"
                      step="1"
                      suffix="円"
                    ></v-text-field>
                  </td>
                  <!--td>
                    <v-text-field
                      v-model.number="item.amount2"
                      type="number"
                      step="1"
                      suffix="円"
                    ></v-text-field>
                  </td-->
                  <!--td>
                    <v-text-field
                      v-model.number="item.amount3"
                      type="number"
                      step="1"
                      suffix="円"
                    ></v-text-field>
                  </td-->
                  <!-- <td>
                    <v-autocomplete
                      v-model="item.subEatMeal"
                      :items="eatMealItems"
                      label="食事有無初期値"
                      :rules="[Rules.Required]"
                      item-text="name"
                      item-value="code"
                      return-object
                    ></v-autocomplete>
                  </td> -->
                  <td>
                    <v-icon
                      small
                      class="ml-1"
                      color="error"
                      @click="deleteAction(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-tab-item>
          <!-- <v-tab-item>
            <v-textarea
              v-model="qualification"
              label="資格"
              prepend-icon="mdi-text"
              outlined
              rows="4"
              class="mt-5"
            ></v-textarea>
            <v-text-field
              v-model.number="careUnitPrice"
              label="介護通常単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.number="careExtUnitPrice"
              label="介護夜朝単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.number="careMidnightUnitPrice"
              label="介護深夜単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.number="disabilityUnitPrice"
              label="障害通常単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.number="disabilityExtUnitPrice"
              label="障害夜朝単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.number="disabilityMidnightUnitPrice"
              label="障害深夜単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.number="otherUnitPrice"
              label="移動その他通常単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.number="otherExtUnitPrice"
              label="移動その他夜朝単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-text-field
              v-model.number="otherMidnightUnitPrice"
              label="移動その他深夜単価"
              prepend-icon="mdi-numeric"
              type="number"
              step="1"
              suffix="円"
              outlined
            ></v-text-field>
            <v-autocomplete
              v-model="hideShift"
              :items="hideShiftItems"
              label="シフト表での非表示設定"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-form-select"
              return-object
            >
            </v-autocomplete>
            <DatePicker
              v-model="hideShiftStartDate"
              label="シフト表での非表示開始日"
              type="month"
              outlined
              prepend-icon="mdi-calendar"
            />
            <v-autocomplete
              v-model="fukushiCarKbn"
              :items="fukushiCarKbnItems"
              label="車種"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-form-select"
              return-object
            >
            </v-autocomplete>
            <v-text-field
              v-model.number="fukushiDistance"
              label="通勤距離"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.1"
              suffix="km"
              outlined
            ></v-text-field>
            <v-autocomplete
              v-model="hidepayd"
              :items="hidepaydday"
              label="シフト表での有給日数非表示"
              :rules="[Rules.Required]"
              outlined
              item-text="name"
              item-value="code"
              prepend-icon="mdi-form-select"
              return-object
            ></v-autocomplete>
          </v-tab-item> -->
        </v-tabs-items>
      </template>

      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import ShainExp from "@/models/ShainExp";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";
import ShozokuAddDialog from "@/views/dialogs/ShozokuAddDialog.vue";

export default {
  name: "ShainExpDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      tab: null,
      tabItems: ["共通", "個別単価設定"],
      params: null,
      add: false,
      edit: false,
      incodes: [],
      selectIncode: { code: "", name: "" },
      selectEatMeal: { code: "", name: "" },
      eatMealItems: [],
      selectWeekWorkStart: { code: "", name: "" },
      weekWorkStartItems: [],
      selectpaymentDayKbn: { conde: "", name: "" },
      paymentDayKbnItems: [],
      selectCarType: { code: "", name: "" },
      carTypeItems: [],
      qualification: "",
      standardWorkTime: 0.0,
      defaultDistance: 0,
      careUnitPrice: 0,
      careExtUnitPrice: 0,
      careMidnightUnitPrice: 0,
      disabilityUnitPrice: 0,
      disabilityExtUnitPrice: 0,
      disabilityMidnightUnitPrice: 0,
      otherUnitPrice: 0,
      otherExtUnitPrice: 0,
      otherMidnightUnitPrice: 0,
      sort: null,
      codeMasterItems: [],
      shozokus: [],
      flexItems: [],
      subEatMeal: { code: "", name: "" },
      subHeaders: [
        "所属名",
        "時給単価",
        //"通勤単価(日額)",
        /*"駐車場単価(日額)",
        "食事有無",*/
        "actions"
      ],
      subDetails: [],
      hideShiftItems: [],
      hidepaydday: [],
      hideShift: { code: "", name: "" },
      hideShiftStartDate: "",
      hidepayd: { code: "", name: "" },
      fukushiCarKbnItems: [],
      fukushiCarKbn: { code: "", name: "" },
      fukushiDistance: 0,
      selectflex: { code: "", name: "" }
      // qualificationLimitLength: v =>
      //   v.length <= 500 || "500文字以内で入力してください"
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    async postRecord(param) {
      await this.$post(this.Paths.shainExp, param);
      this.add = false;
    },
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit");
      this.$loading();
      try {
        const expSubList = [];
        this.subDetails.forEach(e => {
          expSubList.push({
            affiliationId: e.affiliationId,
            amount1: e.amount1,
            amount2: e.amount2,
            amount3: e.amount3,
            amount4: e.amount4,
            amount5: e.amount5,
            amount6: e.amount6,
            subEatMeal: e.subEatMeal.code
          });
        });

        const param = new ShainExp(
          this.selectIncode.code,
          this.selectEatMeal.code,
          this.standardWorkTime,
          this.defaultDistance,
          this.selectWeekWorkStart.code,
          this.selectCarType.code,
          this.qualification,
          this.careUnitPrice,
          this.careExtUnitPrice,
          this.careMidnightUnitPrice,
          this.disabilityUnitPrice,
          this.disabilityExtUnitPrice,
          this.disabilityMidnightUnitPrice,
          this.otherUnitPrice,
          this.otherExtUnitPrice,
          this.otherMidnightUnitPrice,
          this.hideShift.code,
          this.hideShiftStartDate,
          this.hideShiftStartDateStr,
          this.fukushiCarKbn.code,
          this.fukushiDistance,
          this.selectflex.code,
          this.hidepayd.code,
          expSubList
        );

        console.log("submit_param", param);
        await this.postRecord(param);
        this.$info("更新しました。", "社員マスタ拡張");
        this.edit = false;
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async deleteAction(item) {
      console.log("deleteAction", item);
      const confirm = await this.$deleteConfirm(
        "削除します。\nよろしいですか？"
      );
      console.log(confirm);
      if (!confirm) return;

      this.subDetails = this.subDetails.filter(e => e !== item);
    },
    async addShozoku() {
      console.log("addShozoku");
      console.log("addShozokudddd", this.shozokus);
      const param = { shozokus: this.shozokus };
      const selectShozokus = await this.$showDialog(ShozokuAddDialog, {
        args: param
      });

      if (!selectShozokus) return;
      console.log("selectShozokus", selectShozokus);
      selectShozokus.forEach(s => {
        const f = this.subDetails.filter(
          e => String(e.affiliationId) === s.code
        );
        if (f.length == 0) {
          this.subDetails.push({
            affiliationId: s.code,
            displayName: s.name,
            amount1: "0",
            amount2: "0",
            amount3: "0",
            amount4: "0",
            amount5: "0",
            amount6: "0",
            subEatMeal: "0"
          });
        }
      });
    },
    back() {
      const path = "/list/shain-exp";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getShain() {
      console.log("getShain");
      return await this.$get(this.Paths.shain1);
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.EXISTE);
      this.eatMealItems = filter.filter(e => e.code !== NotSetCode.EXISTE);

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.WWS);
      this.weekWorkStartItems = filter;

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.PDK);
      this.paymentDayKbnItems = filter;

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.GASCAR);
      this.carTypeItems = filter;

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.EXEC);
      this.hideShiftItems = filter;

      this.hidepaydday = filter;

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.FCAR);
      this.fukushiCarKbnItems = filter;

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.EXISTE);
      this.flexItems = filter.filter(e => e.code !== NotSetCode.EXISTE);
    }
  },
  async created() {
    console.log(this.name, "createdgggg", this.args);
    this.params = this.$route.params;

    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.incodes = await this.getShain();
      await this.getCodeMasterItems();
      this.getPullDownItems();
      console.log("gg", this.incodes);
      console.log("gg11", this.getCodeMasterItems());
      console.log("gg11112", this.getPullDownItems());

      if (!this.add) {
        this.incodes = this.incodes.filter(
          e => e.code === String(this.args.incode)
        );
        if (this.incodes.length > 0) {
          this.selectIncode.code = this.incodes[0].code;
          this.selectIncode.name = this.incodes[0].name;
        }

        const eatmeal = this.args.eatMeal == null ? "0" : this.args.eatMeal;
        const filterEatMeal = this.eatMealItems.filter(e => e.code === eatmeal);
        if (filterEatMeal.length > 0) {
          this.selectEatMeal.code = filterEatMeal[0].code;
          this.selectEatMeal.name = filterEatMeal[0].name;
        }
        console.log(this.eatMealItems);
        console.log(this.selectEatMeal);

        this.standardWorkTime = this.args.standardWorkTime;
        this.defaultDistance = this.args.defaultDistance;

        const weekworkstart =
          this.args.weekWorkStart == null ? "0" : this.args.weekWorkStart;
        const filterWeekWorkStart = this.weekWorkStartItems.filter(
          e => e.code === weekworkstart
        );
        if (filterWeekWorkStart.length > 0) {
          this.selectWeekWorkStart.code = filterWeekWorkStart[0].code;
          this.selectWeekWorkStart.name = filterWeekWorkStart[0].name;
        }

        const paymentdaykbn =
          this.args.paymentDayKbn == null ? "1" : this.args.paymentDayKbn;
        const filterpaymentDayKbn = this.paymentDayKbnItems.filter(
          e => e.code === paymentdaykbn
        );
        if (filterpaymentDayKbn.length > 0) {
          this.paymentDayKbn.code = filterpaymentDayKbn[0].code;
          this.paymentDayKbn.name = filterpaymentDayKbn[0].name;
        }

        const cartype = this.args.carType == null ? "0" : this.args.carType;
        const filterCarType = this.carTypeItems.filter(e => e.code === cartype);
        if (filterCarType.length > 0) {
          this.selectCarType.code = filterCarType[0].code;
          this.selectCarType.name = filterCarType[0].name;
        }

        const flex = this.args.flexflag == null ? "0" : this.args.flexflag;
        const filterflex = this.flexItems.filter(e => e.code === flex);
        if (filterflex.length > 0) {
          this.selectflex.code = filterflex[0].code;
          this.selectflex.name = filterflex[0].name;
        }

        this.subDetails = this.args.subDetails;

        this.qualification = this.args.qualification;
        this.careUnitPrice = this.args.careUnitPrice;
        this.careExtUnitPrice = this.args.careExtUnitPrice;
        this.careMidnightUnitPrice = this.args.careMidnightUnitPrice;
        this.disabilityUnitPrice = this.args.disabilityUnitPrice;
        this.disabilityExtUnitPrice = this.args.disabilityExtUnitPrice;
        this.disabilityMidnightUnitPrice = this.args.disabilityMidnightUnitPrice;
        this.otherUnitPrice = this.args.otherUnitPrice;
        this.otherExtUnitPrice = this.args.otherExtUnitPrice;
        this.otherMidnightUnitPrice = this.args.otherMidnightUnitPrice;

        const hideshift =
          this.args.hideShift == null ? "0" : this.args.hideShift;
        const filterHideShift = this.hideShiftItems.filter(
          e => e.code === hideshift
        );

        if (filterHideShift.length > 0) {
          this.hideShift.code = filterHideShift[0].code;
          this.hideShift.name = filterHideShift[0].name;
        }

        const hidepaydday =
          this.args.hidePayday == null ? "0" : this.args.hidePayday;
        const filterHidepayd = this.hidepaydday.filter(
          e => e.code === hidepaydday
        );

        if (filterHidepayd.length > 0) {
          this.hidepayd.code = filterHidepayd[0].code;
          this.hidepayd.name = filterHidepayd[0].name;
        }

        const fukushicar =
          this.args.fukushiCarKbn == null ? "0" : this.args.fukushiCarKbn;
        const filterFukushiCarKbnItems = this.fukushiCarKbnItems.filter(
          e => e.code === fukushicar
        );
        if (filterFukushiCarKbnItems.length > 0) {
          this.fukushiCarKbn.code = filterFukushiCarKbnItems[0].code;
          this.fukushiCarKbn.name = filterFukushiCarKbnItems[0].name;
        }
        this.fukushiDistance = this.args.fukushiDistance;
      }
      /*const date = this.args.hideShiftStartDateStr;
      if (date) {
        date.type = "date";
        date.dateInputFormat = "T"; //"yyyy-MM-dd"; // expects 2018-03-16
        date.dateOutputFormat = "M 月 dd 日"; // outputs Mar 16th 2018
      }*/
      this.hideShiftStartDate = this.args.hideShiftStartDateStr;
      this.params.filter.items.forEach(e => {
        if (e.code === "shozokuIncode") {
          this.shozokus = e.models;
        }
      });
      console.log("shozoku", this.shozoku);
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

table th {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  width: 5%;
  padding: 10px 5px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
}

table td {
  text-align: start !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  border-top: none;
  width: 5%;
  padding: 10px 5px;
  font-size: 0.875rem;
}
</style>
